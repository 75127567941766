import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import Auth from '../../services/auth';

export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function* ({ payload }) {
    try {
      const { email, password } = payload;
      const response = yield call(Auth.login, { email, password });
      yield put(actions.loginSuccess(response));
    } catch (error) {
      yield put(actions.loginError(error));
    }
  });
}

export function* oAuthLoginRequest() {
  yield takeEvery(actions.OAUTH_LOGIN_REQUEST, function* ({ payload }) {
    try {
      const { code } = payload;
      const response = yield call(Auth.oAuthLogin, { code });
      yield put(actions.loginSuccess(response));
    } catch (error) {
      yield put(actions.loginError(error));
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* ({ payload }) {
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT_REQUEST, function* () {
    try {
      yield call(Auth.logout);
      yield put(actions.logoutSuccess());
    } catch (error) {
      yield put(actions.logoutError(error));
    }
  });
}

export function* logoutSuccess() {
  yield takeEvery(actions.LOGOUT_SUCCESS, function* () {
    localStorage.removeItem('id_token');
    yield put(push('/'));
  });
}

export function* checkWhoAmI() {
  yield takeEvery(actions.CHECK_WHOAMI, function* () {
    try {
      const response = yield call(Auth.checkWhoAmI);
      yield put(actions.checkWhoAmISuccess(response));
    } catch (error) {
      yield put(actions.checkWhoAmIError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loginRequest), fork(oAuthLoginRequest), fork(loginSuccess), fork(logout), fork(logoutSuccess), fork(checkWhoAmI)]);
}
