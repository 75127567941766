import plFlag from '../assets/flags/pl.svg';
import enFlag from '../assets/flags/en.svg';
import plLogo from '../assets/logo_umed_pl.svg';
import enLogo from '../assets/logo_umed_en.svg';

const SAVE_FUNCTION_CRUD = {
  SAVE: 'save',
  DELETE: 'delete',
  UPDATE: 'update',
};

const DATE_TIME_PICKER_VALUES = {
  FORMAT: 'DD-MM-YYYY HH:mm:ss',
  HOUR_START: '08:00:00',
  HOUR_END: '17:00:00',
  HOUR_FORMAT: 'HH:mm:ss',
  SIMPLE_FORMAT: 'DD-MM-YYYY',
  SIMPLE_FORMAT_TO_SEND: 'YYYY-MM-DD',
};

const MAP_ENUMS = {
  MAP_CONTAINER: '#map',
  MAP_DEFAULT_FLOOR: 'map',
  MAP_STARTING_FLOOR: 'p0',
  MAP_EVENT_LOCATION_FLOOR: 'p0',
  PROJECTION: 'EPSG:3857',
};

const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  PLACEMENT_BOTTOM_RIGHT: 'bottomRight',
  PLACEMENT_TOP_RIGHT: 'topRight',
};

const INTERACTION_FEATURES = {
  DIFFERENCE: 'difference',
  UNION: 'union',
  COPY: 'copy',
  CUT: 'cut',
  PASTE: 'paste',
  SESSION_STORAGE_COPY: 'copiedFeatures',
};

const HELP_SHORTCUTS = {
  COPY: 'CTRL + ALT + C',
  PASTE: 'CTRL + ALT + V',
  CUT: 'CTRL + ALT + X',
  DUPLICATE: 'CTRL + ALT + Z',
  CTRL: 'CTRL',
  SHIFT: 'SHIFT',
};

const UPLOAD_FILE = {
  PHOTO_FORMAT: '.jpg, .jpeg, .png, .gif',
  ICON_FORMAT: '.png',
  VIDEO_FORMAT: '.mp4, .webm',
  AUDIO_FORMAT: '.wav, .mp3',
  PHOTO_SIZE: 2,
  ICON_SIZE: 0.02,
  VIDEO_SIZE: 120,
  AUDIO_SIZE: 10,
  PHOTO_FORMAT_PNG: 'image/png',
  PHOTO_FORMAT_JPEG: 'image/jpeg',
  PHOTO_FORMAT_GIF: 'image/gif',
  VIDEO_FORMAT_MP4: 'video/mp4',
  VIDEO_FORMAT_WEBM: 'video/webm',
  AUDIO_FORMAT_WAV: 'audio/wav',
  AUDIO_FORMAT_MP3: 'audio/mpeg',
  PHOTO_STATUS_UPLOADING: 'uploading',
  PHOTO_STATUS_DONE: 'done',
  UPLOAD_NAME: 'photo',
  BUTTON_ICON_LOADING: 'loading',
  BUTTON_ICON_DONE: 'plus',
};

const LOCATION_FINDER = {
  LAT_LON_DIVIDER: ',',
  DEFAULT_LATITUDE: null,
  DEFAULT_LONGITUDE: null,
  DEFAULT_COORDS: [null, null],
  DEFAULT_NAME: '',
  INPUT_STEP: 0.01,
};

const API_STATUS = {
  SUCCESS: 'success',
  UNAUTHENTICATED: 'Unauthenticated.',
  FORBIDDEN: 'User does not have the right roles.',
};

const PAGINATION_DEFAULT = {
  PER_PAGE: 10,
  DATA: [],
  TOTAL: 0,
};

const FORM_COMPONENT_TYPE = {
  INPUT: 'string',
  INPUT_NUMBER: 'integer',
  INPUT_PROFILE: 'integer_profile',
  SELECT: 'external_id',
  SWITCH: 'boolean',
  CATEGORY: 'category',
  UMED_ROOM: 'umed_room',
  SELECT_CONNECTORS: 'select',
};

const STATUS_TYPE = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

const FLAGS = {
  pl: plFlag,
  en: enFlag,
};

const LOGOS = {
  pl: plLogo,
  en: enLogo,
};

const OVERFLOW_STRING = {
  NUMBER_OF_CHARACTERS_TO_CUT: 50,
  END_OF_OVERFLOW: '...',
};

const NOTIFICATIONS_TYPES = {
  GENERAL: 'general',
  MARKETING: 'marketing',
  PUSH: 'push',
  ROUTE: 'route',
};

const BUTTONS_TYPES = {
  BEFORE_ARTICLES: 'before_articles',
  BEFORE_EVENTS: 'before_events',
};

const USER_ROLES = {
  ADMIN: 1,
  MODERATOR: 2,
  AD_USER: 3,
  AD_EMPLOYEE: 4,
  USER: 5,
};

const IS_NOTIFICATION_ARTICLES_EVENTS_VALUE = false;

const IS_CATEGORY_ENABLE_VALUE = true;

const IS_CATEGORY_DISABLED_VALUE = false;

const INPUT_TEXTAREA_ROWS = 5;

const DEFAULT_FILTER_RANGE = 150;

const MAP_FLOORS = [
  {
    label: '-2',
    value: 'p-2',
  },
  {
    label: '-1',
    value: 'p-1',
  },
  {
    label: '0',
    value: 'p0',
  },
  {
    label: '1',
    value: 'p1',
  },
  {
    label: '2',
    value: 'p2',
  },
  {
    label: '3',
    value: 'p3',
  },
  {
    label: '4',
    value: 'p4',
  },
  {
    label: '5',
    value: 'p5',
  },
  {
    label: '6',
    value: 'p6',
  },
  {
    label: '7',
    value: 'p7',
  },
  {
    label: '8',
    value: 'p8',
  },
  {
    label: '9',
    value: 'p9',
  },
  {
    label: '10',
    value: 'p10',
  },
  {
    label: '11',
    value: 'p11',
  },
  {
    label: '12',
    value: 'p12',
  },
  {
    label: '13',
    value: 'p13',
  },
  {
    label: '14',
    value: 'p14',
  },
  {
    label: '15',
    value: 'p15',
  },
  {
    label: '16',
    value: 'p16',
  },
  {
    label: '17',
    value: 'p17',
  },
  {
    label: '18',
    value: 'p18',
  },
  {
    label: '19',
    value: 'p19',
  },
  {
    label: '20',
    value: 'p20',
  },
];

const UPLOAD_VALUES = {
  PHOTO: 'photo',
  VIDEO: 'video',
  ICON: 'icon',
  AUDIO: 'audio',
};

export {
  SAVE_FUNCTION_CRUD,
  DATE_TIME_PICKER_VALUES,
  MAP_ENUMS,
  NOTIFICATION_TYPES,
  INTERACTION_FEATURES,
  HELP_SHORTCUTS,
  UPLOAD_FILE,
  LOCATION_FINDER,
  API_STATUS,
  PAGINATION_DEFAULT,
  FORM_COMPONENT_TYPE,
  STATUS_TYPE,
  FLAGS,
  LOGOS,
  OVERFLOW_STRING,
  NOTIFICATIONS_TYPES,
  BUTTONS_TYPES,
  USER_ROLES,
  IS_NOTIFICATION_ARTICLES_EVENTS_VALUE,
  IS_CATEGORY_ENABLE_VALUE,
  IS_CATEGORY_DISABLED_VALUE,
  INPUT_TEXTAREA_ROWS,
  MAP_FLOORS,
  UPLOAD_VALUES,
  DEFAULT_FILTER_RANGE,
};
