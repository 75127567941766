import styled from 'styled-components';
import { palette } from 'styled-theme';

const ButtonHolders = styled.div``;

const ButtonStyle = ComponentName => styled(ComponentName)`
  &.ant-btn-primary,
  &.ant-btn-primary:focus {
    background-color: ${palette('primary', 0)};
    border-color: ${palette('primary', 0)};
    color: ${palette('text', 5)};
  }

  &.ant-btn-primary:hover {
    background-color: ${palette('text', 5)};
    border-color: ${palette('primary', 0)};
    color: ${palette('primary', 0)};
  }

  &.ant-btn-oauth2,
  &.ant-btn-oauth2:focus {
    background-color: #2f2f2f;
    border-color: #2f2f2f;
    color: #ffffff;
  }

  &.ant-btn-oauth2:hover {
    background-color: #ffffff;
    border-color: #2f2f2f;
    color: #2f2f2f;
  }
`;

const ButtonStyleToRight = ComponentName => styled(ComponentName)`
  float: right;

  &.ant-btn-primary,
  &.ant-btn-primary:focus {
    background-color: ${palette('primary', 0)};
    border-color: ${palette('primary', 0)};
    color: ${palette('text', 5)};
  }

  &.ant-btn-primary:hover {
    background-color: ${palette('text', 5)};
    border-color: ${palette('primary', 0)};
    color: ${palette('primary', 0)};
  }
`;

export { ButtonHolders, ButtonStyle, ButtonStyleToRight };
