import asyncComponent from '../../utils/AsyncFunc';
import { ROUTER_URLS } from '../../utils/enumsUrl';

export const commonRoutes = [
  {
    path: ROUTER_URLS.CMS,
    component: asyncComponent(() => import('../../components/DashboardSwitcher')),
  },
  {
    path: ROUTER_URLS.OAUTH2,
    component: asyncComponent(() => import('../../components/OAuth2MicrosoftLogin')),
  },
];

export const adminRoutes = [
  {
    path: ROUTER_URLS.MAP + '/:floor',
    component: asyncComponent(() => import('../../pages/HomePage/HomePage')),
  },
  {
    path: ROUTER_URLS.CATEGORIES,
    component: asyncComponent(() => import('../../pages/Categories/Categories')),
  },
  {
    path: ROUTER_URLS.CATEGORIES_MODIFY,
    component: asyncComponent(() => import('../../pages/Categories/CategoryModify')),
  },
  {
    path: ROUTER_URLS.ARTICLES,
    component: asyncComponent(() => import('../../pages/Articles/Articles')),
  },
  {
    path: ROUTER_URLS.ARTICLES_MODIFY,
    component: asyncComponent(() => import('../../pages/Articles/ArticleModify')),
  },
  {
    path: ROUTER_URLS.EVENTS,
    component: asyncComponent(() => import('../../pages/Events/Events')),
  },
  {
    path: ROUTER_URLS.EVENTS_MODIFY,
    component: asyncComponent(() => import('../../pages/Events/EventModify')),
  },
  {
    path: ROUTER_URLS.CATEGORIES_CRUDS,
    component: asyncComponent(() => import('../../pages/CategoriesCruds/CategoriesCruds')),
  },
  {
    path: ROUTER_URLS.CATEGORIES_CRUDS_MODIFY,
    component: asyncComponent(() => import('../../pages/CategoriesCruds/CategoryCrudsModify')),
  },
  // {
  //   path: ROUTER_URLS.SOS,
  //   component: asyncComponent(() => import('../../pages/Sos/Sos')),
  // },
  // {
  //   path: ROUTER_URLS.SOS_SEND_PUSH,
  //   component: asyncComponent(() => import('../../pages/Sos/SosSendPush')),
  // },
  // {
  //   path: ROUTER_URLS.SOS_CONTACTS,
  //   component: asyncComponent(() => import('../../pages/SosContacts/SosContacts')),
  // },
  // {
  //   path: ROUTER_URLS.SOS_CONTACTS_MODIFY,
  //   component: asyncComponent(() => import('../../pages/SosContacts/SosContactModify')),
  // },
  {
    path: ROUTER_URLS.USERS_MODIFY_PASSWORD,
    component: asyncComponent(() => import('../../pages/Users/UserPasswordModify')),
  },
  {
    path: ROUTER_URLS.BUTTONS,
    component: asyncComponent(() => import('../../pages/Buttons/Buttons')),
  },
  {
    path: ROUTER_URLS.BUTTONS_MODIFY,
    component: asyncComponent(() => import('../../pages/Buttons/ButtonModify')),
  },
  {
    path: ROUTER_URLS.ADVERTISEMENTS,
    component: asyncComponent(() => import('../../pages/Advertisements/Advertisements')),
  },
  {
    path: ROUTER_URLS.ADVERTISEMENTS_MODIFY,
    component: asyncComponent(() => import('../../pages/Advertisements/AdvertisementModify')),
  },
  {
    path: ROUTER_URLS.NOTIFICATIONS,
    component: asyncComponent(() => import('../../pages/Notifications/Notifications')),
  },
  {
    path: ROUTER_URLS.NOTIFICATIONS_MODIFY,
    component: asyncComponent(() => import('../../pages/Notifications/NotificationModify')),
  },
  {
    path: ROUTER_URLS.NOTIFICATIONS_PUSH,
    component: asyncComponent(() => import('../../pages/NotificationsPush/NotificationsPush')),
  },
  {
    path: ROUTER_URLS.NOTIFICATIONS_PUSH_MODIFY,
    component: asyncComponent(() => import('../../pages/NotificationsPush/NotificationPushModify')),
  },
  {
    path: ROUTER_URLS.NOTIFICATIONS_ROUTE,
    component: asyncComponent(() => import('../../pages/NotificationsRoute/NotificationsRoute')),
  },
  {
    path: ROUTER_URLS.NOTIFICATIONS_ROUTE_MODIFY,
    component: asyncComponent(() => import('../../pages/NotificationsRoute/NotificationsRouteModify')),
  },
  {
    path: ROUTER_URLS.CAMPUSES,
    component: asyncComponent(() => import('../../pages/Campuses/Campuses')),
  },
  {
    path: ROUTER_URLS.CAMPUSES_MODIFY,
    component: asyncComponent(() => import('../../pages/Campuses/CampusModify')),
  },
  {
    path: ROUTER_URLS.PARKING_LOTS,
    component: asyncComponent(() => import('../../pages/ParkingLots/ParkingLots')),
  },
  {
    path: ROUTER_URLS.PARKING_LOTS_MODIFY,
    component: asyncComponent(() => import('../../pages/ParkingLots/ParkingModify')),
  },
  {
    path: ROUTER_URLS.PARKING_LOTS_LEGEND,
    component: asyncComponent(() => import('../../pages/ParkingLots/ParkingLotsLegend')),
  },
  {
    path: ROUTER_URLS.USERS,
    component: asyncComponent(() => import('../../pages/Users/Users')),
  },
  {
    path: ROUTER_URLS.USERS_MODIFY,
    component: asyncComponent(() => import('../../pages/Users/UserModify')),
  },
  {
    path: ROUTER_URLS.BEACONS,
    component: asyncComponent(() => import('../../pages/Beacons/Beacons')),
  },
  {
    path: ROUTER_URLS.BEACONS_MODIFY,
    component: asyncComponent(() => import('../../pages/Beacons/BeaconModify')),
  },
  {
    path: ROUTER_URLS.OPINIONS,
    component: asyncComponent(() => import('../../pages/Opinions/Opinions')),
  },
];

export const moderatorRoutes = [
  {
    path: ROUTER_URLS.MAP + '/:floor',
    component: asyncComponent(() => import('../../pages/HomePage/HomePage')),
  },
  {
    path: ROUTER_URLS.CATEGORIES,
    component: asyncComponent(() => import('../../pages/Categories/Categories')),
  },
  {
    path: ROUTER_URLS.CATEGORIES_MODIFY,
    component: asyncComponent(() => import('../../pages/Categories/CategoryModify')),
  },
  {
    path: ROUTER_URLS.ARTICLES,
    component: asyncComponent(() => import('../../pages/Articles/Articles')),
  },
  {
    path: ROUTER_URLS.ARTICLES_MODIFY,
    component: asyncComponent(() => import('../../pages/Articles/ArticleModify')),
  },
  {
    path: ROUTER_URLS.EVENTS,
    component: asyncComponent(() => import('../../pages/Events/Events')),
  },
  {
    path: ROUTER_URLS.EVENTS_MODIFY,
    component: asyncComponent(() => import('../../pages/Events/EventModify')),
  },
  {
    path: ROUTER_URLS.CATEGORIES_CRUDS,
    component: asyncComponent(() => import('../../pages/CategoriesCruds/CategoriesCruds')),
  },
  {
    path: ROUTER_URLS.CATEGORIES_CRUDS_MODIFY,
    component: asyncComponent(() => import('../../pages/CategoriesCruds/CategoryCrudsModify')),
  },
  // {
  //   path: ROUTER_URLS.SOS,
  //   component: asyncComponent(() => import('../../pages/Sos/Sos')),
  // },
  // {
  //   path: ROUTER_URLS.SOS_SEND_PUSH,
  //   component: asyncComponent(() => import('../../pages/Sos/SosSendPush')),
  // },
  // {
  //   path: ROUTER_URLS.SOS_CONTACTS,
  //   component: asyncComponent(() => import('../../pages/SosContacts/SosContacts')),
  // },
  // {
  //   path: ROUTER_URLS.SOS_CONTACTS_MODIFY,
  //   component: asyncComponent(() => import('../../pages/SosContacts/SosContactModify')),
  // },
  {
    path: ROUTER_URLS.USERS_MODIFY_PASSWORD,
    component: asyncComponent(() => import('../../pages/Users/UserPasswordModify')),
  },
  {
    path: ROUTER_URLS.BUTTONS,
    component: asyncComponent(() => import('../../pages/Buttons/Buttons')),
  },
  {
    path: ROUTER_URLS.BUTTONS_MODIFY,
    component: asyncComponent(() => import('../../pages/Buttons/ButtonModify')),
  },
  {
    path: ROUTER_URLS.ADVERTISEMENTS,
    component: asyncComponent(() => import('../../pages/Advertisements/Advertisements')),
  },
  {
    path: ROUTER_URLS.ADVERTISEMENTS_MODIFY,
    component: asyncComponent(() => import('../../pages/Advertisements/AdvertisementModify')),
  },
  {
    path: ROUTER_URLS.NOTIFICATIONS,
    component: asyncComponent(() => import('../../pages/Notifications/Notifications')),
  },
  {
    path: ROUTER_URLS.NOTIFICATIONS_MODIFY,
    component: asyncComponent(() => import('../../pages/Notifications/NotificationModify')),
  },
  {
    path: ROUTER_URLS.NOTIFICATIONS_PUSH,
    component: asyncComponent(() => import('../../pages/NotificationsPush/NotificationsPush')),
  },
  {
    path: ROUTER_URLS.NOTIFICATIONS_PUSH_MODIFY,
    component: asyncComponent(() => import('../../pages/NotificationsPush/NotificationPushModify')),
  },
  {
    path: ROUTER_URLS.NOTIFICATIONS_ROUTE,
    component: asyncComponent(() => import('../../pages/NotificationsRoute/NotificationsRoute')),
  },
  {
    path: ROUTER_URLS.NOTIFICATIONS_ROUTE_MODIFY,
    component: asyncComponent(() => import('../../pages/NotificationsRoute/NotificationsRouteModify')),
  },
  {
    path: ROUTER_URLS.CAMPUSES,
    component: asyncComponent(() => import('../../pages/Campuses/Campuses')),
  },
  {
    path: ROUTER_URLS.CAMPUSES_MODIFY,
    component: asyncComponent(() => import('../../pages/Campuses/CampusModify')),
  },
  {
    path: ROUTER_URLS.PARKING_LOTS,
    component: asyncComponent(() => import('../../pages/ParkingLots/ParkingLots')),
  },
  {
    path: ROUTER_URLS.PARKING_LOTS_MODIFY,
    component: asyncComponent(() => import('../../pages/ParkingLots/ParkingModify')),
  },
  {
    path: ROUTER_URLS.PARKING_LOTS_LEGEND,
    component: asyncComponent(() => import('../../pages/ParkingLots/ParkingLotsLegend')),
  },
  {
    path: ROUTER_URLS.BEACONS,
    component: asyncComponent(() => import('../../pages/Beacons/Beacons')),
  },
  {
    path: ROUTER_URLS.BEACONS_MODIFY,
    component: asyncComponent(() => import('../../pages/Beacons/BeaconModify')),
  },
  {
    path: ROUTER_URLS.OPINIONS,
    component: asyncComponent(() => import('../../pages/Opinions/Opinions')),
  },
];
