import actions from './actions';

const initState = {
  idToken: null,
  loading: false,
  error: null,
  user: null,
  roleLoaded: false,
};

export default function authReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.OAUTH_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: payload.token,
        loading: false,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false,
        idToken: null,
      };

    case actions.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.LOGOUT_SUCCESS:
      return {
        ...state,
        idToken: null,
        loading: false,
        user: null,
        roleLoaded: false,
      };
    case actions.LOGOUT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case actions.CHECK_WHOAMI:
      return {
        ...state,
        roleLoaded: false,
        error: null,
      };

    case actions.CHECK_WHOAMI_SUCCESS:
      return {
        ...state,
        roleLoaded: true,
        error: null,
        user: payload.data,
      };

    case actions.CHECK_WHOAMI_ERROR:
      return {
        ...state,
        roleLoaded: true,
        error: payload.error,
      };

    default:
      return state;
  }
}
