export default {
  YES: 'Yes',
  NO: 'No',
  ID: 'Id',
  ARE_YOU_SURE: 'Are you sure?',

  NAME: 'Name',
  COLOR: 'Color',
  ICON: 'Icon',
  TEXT: 'Text',
  HOLD: 'Hold',
  IS_ENABLED: 'Activity',

  MENU_MAP: 'Maps',
  MENU_CATEGORIES: 'Map categories',
  MENU_ARTICLES: 'News',
  MENU_EVENTS: 'Events',
  MENU_SOS: 'SOS',
  MENU_SOS_CONTACTS: 'SOS contacts list',
  MENU_USERS: 'Users',
  MENU_CATEGORIES_CRUDS: 'Categories',
  MENU_BUTTONS: 'Buttons',
  MENU_ADVERTISEMENTS: 'Advertisements',
  MENU_NOTIFICATIONS: 'Notifications',
  MENU_NOTIFICATIONS_PUSH: 'PUSH Notifications',
  MENU_CAMPUSES: 'Campuses labels',
  MENU_PARKING_LOT: 'Parking lots',
  MENU_NOTIFICATIONS_ROUTE: 'Routes notifications',
  MENU_BEACONS: 'Beacons',
  MENU_OPINIONS: 'Opinions',

  MENU_SNAP_SWITCH: 'snap',
  MAP_BUTTON_SAVE: 'save changes',
  MENU_EDIT_MODE: 'edit mode',
  MAP_GENERATE_GRAPH: 'generate graph',

  MENU_FEATURE_FILTER: 'X',
  MENU_FEATURE_FILTER_CHECK_ALL: 'X',
  MENU_FEATURE_FILTER_UNCHECK_ALL: 'X',

  MAP_MENU_ROTATION_LABEL: 'rotation in degrees',
  MAP_MENU_ZOOM_LABEL: 'zoom',
  MAP_MENU_SQUARE_NAME: 'square',
  MAP_MENU_BOX_NAME: 'box',
  MAP_MENU_POLYGON_NAME: 'polygon',
  MAP_MENU_UNION_NAME: 'merge',
  MAP_MENU_DIFFERENCE_NAME: 'difference',
  MAP_MENU_UNGROUP_NAME: 'ungroup',
  MAP_MODAL_ERROR_TITLE: 'The operation failed',
  MAP_MODAL_MERGING_ERROR: 'Cannot merge. One of the selected objects is a point or line.',
  MAP_MODAL_HELP_TITLE: 'User manual',
  MAP_MODAL_HELP_HEADER: 'Keyboard shortcuts:',
  MAP_MODAL_HELP_COPY: 'copy the selected objects',
  MAP_MODAL_HELP_CUT: 'cut the selected objects',
  MAP_MODAL_HELP_PASTE: 'paste the copied objects',
  MAP_MODAL_HELP_DUPLICATE: 'duplicate selected objects',
  MAP_MODAL_HELP_EDIT_CTRL: 'while editing a shape to scale in one direction only',
  MAP_MODAL_HELP_EDIT_SHIFT: 'while editing a shape to preserve proportions when scaling',
  ROTATION_LABEL: 'rotation in degrees',

  CHOOSE_MAP_OBJECT_TYPE: 'X',

  TABLE_SHOWING: 'Showing',
  TABLE_OF: 'of',
  TABLE_RESULTS: 'Results',
  TABLE_NO_DATA: 'No Data',
  TABLE_SEARCH: 'Search',
  TABLE_RESET: 'Reset',

  CRUD_ADD_NEW: 'Add new record',
  CRUD_SAVE: 'Save',
  CRUD_UPDATE: 'Update',
  CRUD_CANCEL: 'Cancel',
  CRUD_ARE_YOU_SURE: 'Are you sure to delete this record?',
  CRUD_ACTIONS: 'Actions',
  CRUD_ADD_RECORD_SUCCESS: 'New record added successfully',
  CRUD_EDIT_RECORD_SUCCESS: 'Record edited successfully',
  CRUD_REMOVE_RECORD_SUCCESS: 'Record deleted successfully',
  CRUD_CLOSE: 'Close',
  CRUD_SEND: 'Send',

  FORMS_EMAIL: 'E-mail',
  FORMS_EMAIL_OR_LOGIN: 'E-mail or login',
  FORMS_PASSWORD: 'Password',
  FORMS_CONFIRM_PASSWORD: 'Confirm password',
  FORMS_FIELD_REQUIRED: 'This field is required',
  FORMS_EMAIL_NOT_CORRECT: 'E-mail must be correct',
  FORMS_PASSWORD_NOT_CORRECT: 'The password must contain at least one upper case letter, lower case letter and one number',
  FORMS_NOT_SAME_PASSWORD: 'Passwords must be the same',

  AUTH_SIGNIN: 'Sign in',
  AUTH_SIGNIN_WITH_MICROSOFT: 'Microsoft',
  AUTH_LOGOUT: 'Logout',
  AUTH_CONFIRM: 'Confirm',
  AUTH_FORGOT_PASSWORD: 'Forgot password?',
  AUTH_FORGOT_PASSWORD_SUCCESS: 'E-mail with a reset password link has been sent to the given e-mail address',
  AUTH_FORGOT_PASSWORD_SUBTITLE: 'Forgot password?',
  AUTH_FORGOT_PASSWORD_DESCRIPTION: 'Please enter a registered e-mail address.',
  AUTH_FORGOT_PASSWORD_DESCRIPTION_LDAP: 'If you have an LDAP account, please contact your administrator.',
  AUTH_BACK_TO_SIGNIN: 'Back to login page',
  AUTH_RESET_PASSWORD_SUBTITLE: 'Reset password',
  AUTH_RESET_PASSWORD_DESCRIPTION: 'Enter a new password and confirm it',
  AUTH_RESET_PASSWORD_SUCCESS: 'Password has been changed to a new one. You can login',
  AUTH_BUTTON_OR: 'or',
  AUTH_LOGIN_IN_PROGRESS: 'Login in progress',

  TITLE_404: '404',
  SUBTITLE_404: 'An obstacle has arisen',
  DESC_404: 'The page you are looking for probably does not exist or has been moved..',
  BACK_404: 'Back to home page',
  TITLE_403: '403',
  SUBTITLE_403: 'An obstacle has arisen',
  DESC_403: 'You cannot get access to this resource..',
  DESC_403_2: 'Contact your administrator to grant access',
  BACK_403: 'Back to home page',
  SERVER_ERROR: 'Unknown server error',

  SELECT_VALUE_ALL: 'All',
  SELECT_VALUE_CAMPUS: 'Campus',
  SELECT_VALUE_BUILDING: 'Building',
  SELECT_VALUE_ROOM: 'Room',
  SELECT_VALUE_BEACON: 'Beacon',
  SELECT_VALUE_POI: 'POI',
  SELECT_VALUE_ROUTE: 'Route',
  SELECT_VALUE_LEVEL: 'Level',
  SELECT_VALUE_ROI: 'ROI',
  SELECT_VALUE_ROUTE_ROUTING: 'Route routing',
  SELECT_VALUE_WALL: 'Wall',
  SELECT_VALUE_STAIRS: 'Stairs',
  SELECT_VALUE_BACKGROUND: 'Background',
  SELECT_VALUE_DOOR: 'Door',
  SELECT_VALUE_PARKING: 'Parking',
  SELECT_VALUE_WINDOWS: 'Window',
  SELECT_VALUE_FURNITURE: 'Furniture',
  SELECT_VALUE_CONNECTOR: 'Connector',

  SELECT_VALUE_POINT: 'Point',
  SELECT_VALUE_POLYGON: 'Polygon',
  SELECT_VALUE_CIRCLE: 'Circle',
  SELECT_VALUE_SQUARE: 'Square',
  SELECT_VALUE_BOX: 'Box',
  SELECT_VALUE_LINESTRING: 'LineString',

  MODAL_DELETE_FEATURE_TITLE_CORRECT: 'Are you sure?',
  MODAL_DELETE_FEATURE_TITLE_INCORRECT: 'Select some object!',
  MODAL_DELETE_FEATURE_OK_CORRECT: 'YES',
  MODAL_DELETE_FEATURE_OK_INCORRECT: 'OK',
  MODAL_DELETE_FEATURE_CANCEL_CORRECT: 'NO',
  MODAL_DELETE_FEATURE_TEXT_CORRECT:
    'Are you sure? The operation cannot be reversed and the selected object will be removed permanently!',
  MODAL_DELETE_FEATURE_TEXT_INCORRECT:
    'No object selected - no information is available on which object to delete. You must select the shape before performing the requested operation.',

  NOT_EMPTY_COLLECTIONS_BUTTON_OK: 'Accept & Continue',
  NOT_EMPTY_COLLECTIONS_BUTTON_CANCEL: 'Cancel',
  NOT_EMPTY_COLLECTIONS_TITLE: 'There are unsaved changes on map objects',
  NOT_EMPTY_COLLECTIONS_TEXT:
    'The data that has not been saved will be irretrievably deleted and you will go to the desired view.',

  DRAWER_OBJECT_MODIFY_BUTTON_SUBMIT: 'Save',
  DRAWER_OBJECT_MODIFY_NOTIFICATION_SUCCESS: 'Object saved!',
  DRAWER_OBJECT_MODIFY_NOTIFICATION_ERROR: 'Check that all required fields have been completed',

  NOTIFICATION_PHOTO_ERROR_FORMAT: 'Only JPG/PNG',
  NOTIFICATION_PHOTO_GIF_ERROR_FORMAT: 'Only JPG/PNG/GIF',
  NOTIFICATION_ICON_ERROR_FORMAT: 'Only PNG',
  NOTIFICATION_VIDEO_ERROR_FORMAT: 'Only MP4/WEBM',
  NOTIFICATION_AUDIO_ERROR_FORMAT: 'Only WAV/MP3',
  NOTIFICATION_PHOTO_ERROR_SIZE: 'Image must be less than 2MB',
  NOTIFICATION_ICON_ERROR_SIZE: 'Icon must be less than 20KB',
  NOTIFICATION_VIDEO_ERROR_SIZE: 'Video must be less than 120MB',
  NOTIFICATION_AUDIO_ERROR_SIZE: 'Audio must be less than 10MB',

  NOTIFICATION_INFO_NO_DATA: 'No changes have been made',
  NOTIFICATION_INFO_SAVE_DATA_SUCCESS: 'Changes were uploaded to the server for processing',
  NOTIFICATION_INFO_SAVE_DATA_ERROR: 'An unexpected error has occurred',
  NOTIFICATION_INFO_GENERATE_GRAPH: 'Generating a graph in progress... This may take a while...',

  LOCATION_FINDER_CATEGORIES_FROM_MAP: 'Search from existing locations',
  LOCATION_FINDER_CATEGORIES_LAT_LON: 'Mark a point on the map',

  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',

  NO_DATA: 'No data',
  PROMPT_MESSAGE: 'The changes you make may not be saved.',

  REQUIRED_FIELD_NOT_CORRECT: 'Check that all required fields have been completed',

  //STATUS OPTIONS
  STATUS_OPTION_ACTIVE: 'Active',
  STATUS_OPTION_INACTIVE: 'Inactive',

  //LANGUAGES
  LANGUAGE_PL: 'Polish',
  LANGUAGE_EN: 'English',

  //NOTIFICATIONS TYPES
  NOTIFICATION_TYPE_GENERAL: 'General',
  NOTIFICATION_TYPE_MARKETING: 'Marketing',
  NOTIFICATION_TYPE_PUSH: 'Push',
  NOTIFICATION_TYPE_ROUTE: 'Route',

  //BUTTONS TYPES$
  BUTTON_TYPE_BEFORE_ARTICLES: 'Before news',
  BUTTON_TYPE_BEFORE_EVENTS: 'Before events',

  //USER ROLES
  USER_ROLE_ADMIN: 'Admin',
  USER_ROLE_MODERATOR: 'Moderator',
  USER_ROLE_AD_USER: 'User from AD',
  USER_ROLE_AD_EMPLOYEE: 'Employee from AD',
  USER_ROLE_USER: 'User',

  //UPLOAD_VALUES
  UPLOAD_VALUE_PHOTO: 'Photo',
  UPLOAD_VALUE_ICON: 'Icon',
  UPLOAD_VALUE_VIDEO: 'Video',
  UPLOAD_VALUE_AUDIO: 'Audio',

  //TOOLTIPS MENU INTERACTION
  TOOLTIP_SELECT: 'Select mode',
  TOOLTIP_TYPE_SHAPES: 'XXX',
  TOOLTIP_LINE: 'Line',
  TOOLTIP_POINT: 'Point',
  TOOLTIP_MODIFY: 'Edit',
  TOOLTIP_COPYCUT: 'Copy/cut',
  TOOLTIP_PASTE: 'Paste',
  TOOLTIP_TYPE_MERGE: 'XXX',
  TOOLTIP_DELETE: 'Delete',
  TOOLTIP_HELP: 'Help',

  //CRUDS TLN
  CATEGORIES_NAME_PL: 'Name (PL)',
  CATEGORIES_NAME_EN: 'Name (EN)',
  CATEGORIES_IS_VISIBLE: 'X',
  CATEGORIES_IS_VISIBLE_ICON: 'X',
  CATEGORIES_IS_VISIBLE_FILTER: 'X',
  CATEGORIES_FILTER_RANGE: 'X',
  CATEGORIES_TITLE: 'Map categories',
  CATEGORIES_ADD_CATEGORY: 'Add map category',
  CATEGORIES_EDIT_CATEGORY: 'Edit map category',

  ARTICLES_TITLE: 'News',
  ARTICLES_ADD_ARTICLE: 'Add news',
  ARTICLES_EDIT_ARTICLE: 'Edit news',
  ARTICLES_PREVIEW: 'Preview',
  ARTICLES_PREVIEW_TITLE: 'View',
  ARTICLES_CATEGORY: 'Category',
  ARTICLES_PHOTO: 'Photo',
  ARTICLES_STATUS: 'Status',
  ARTICLES_LANGUAGE: 'Language',
  ARTICLES_CAMPUS: 'Campus',
  ARTICLES_NOTIFICATION: 'PUSH notification about news',

  SINGLE_ARTICLE_NAME: 'Name',
  SINGLE_ARTICLE_CATEGORY: 'Category',
  SINGLE_ARTICLE_DESCRIPTION: 'Description',
  SINGLE_ARTICLE_PHOTO: 'Photo',
  SINGLE_ARTICLE_STATUS: 'Status',
  SINGLE_ARTICLE_LANGUAGE: 'Language',

  ARTICLE_EVENT_NOTIFICATION_SENT: 'Notification has already been sent',
  ARTICLES_EVENTS_NOTIFICATION_TITLE: 'Notification',

  EVENTS_TITLE: 'Events',
  EVENTS_ADD_EVENT: 'Add event',
  EVENTS_EDIT_EVENT: 'Edit event',
  EVENTS_PREVIEW: 'Preview',
  EVENTS_PREVIEW_TITLE: 'View',
  EVENTS_CATEGORY: 'Category',
  EVENTS_PHOTO: 'Photo',
  EVENTS_LOCATION: 'Location',
  EVENTS_LOCATION_PLACEHOLDER: 'Start typing..',
  EVENTS_LOCATION_TYPE_PLACEHOLDER: 'Choose location category',
  EVENTS_STATUS: 'Status',
  EVENTS_LANGUAGE: 'Language',
  EVENTS_CAMPUS: 'Campus',
  EVENTS_NOTIFICATION: 'PUSH notification about event',

  EVENT_LOCATION: 'Location',
  EVENT_DATE: 'Date',

  SINGLE_EVENT_NAME: 'Name',
  SINGLE_EVENT_CATEGORY: 'Category',
  SINGLE_EVENT_DESCRIPTION: 'Description',
  SINGLE_EVENT_LOCATION: 'Location',
  SINGLE_EVENT_DATE: 'Date',
  SINGLE_EVENT_PHOTO: 'Photo',
  SINGLE_EVENT_STATUS: 'Status',
  SINGLE_EVENT_LANGUAGE: 'Language',

  CATEGORIES_CRUDS_TITLE: 'News and events categories',
  CATEGORIES_CRUDS_ADD_CATEGORY: 'Add news and events category',
  CATEGORIES_CRUDS_EDIT_CATEGORY: 'Edit news and events category',

  CATEGORIES_CRUDS_NAME_PL: 'Name (PL)',
  CATEGORIES_CRUDS_NAME_EN: 'Name (EN)',

  SOS_TITLE: 'SOS',
  SOS_USER: 'User',
  SOS_USER_UNAUTHENTICATED: 'User not logged',
  SOS_WHERE: 'Location',
  SOS_DATE: 'Date',
  SOS_PREVIEW_TITLE: 'View',
  SOS_PREVIEW: 'Preview',
  SOS_LANGUAGE: 'Language',

  SOS_PUSH_SEND: 'Reply',
  SOS_PUSH_REPLY: 'View reply',
  SOS_PUSH_SEND_TITLE: 'Send reply push',
  SOS_PUSH_SEND_SUCCESS: 'Sending reply push successfully completed',
  SOS_PUSH_TITLE: 'Title',
  SOS_PUSH_DESCRIPTION: 'Message',
  SOS_PUSH_MODAL_TITLE: 'Reply',

  SINGLE_SOS_NAME: 'Name',
  SINGLE_SOS_WHERE: 'Where',
  SINGLE_SOS_STATUS: 'Status',
  SINGLE_SOS_DATE: 'Date',
  SINGLE_SOS_DESCRIPTION: 'Note',

  USER: 'User',
  USERS_TITLE: 'Users',
  USER_NAME: 'Name and last name',
  USER_EMAIL: 'E-mail',
  USER_ROLE: 'Role',
  USER_ADD: 'Add user',
  USER_EDIT: 'Edit user',
  USER_EDIT_CRUD: 'Edit',
  USER_PASSWORD: 'Password',
  USER_PASSWORD_CONFIRM: 'Confirm password',
  USER_PASSWORD_CHANGE: 'Change password',

  BUTTONS_TITLE: 'Buttons',
  BUTTONS_ADD_BUTTON: 'Add button',
  BUTTONS_EDIT_BUTTON: 'Edit button',
  BUTTONS_PREVIEW: 'Preview',
  BUTTONS_PREVIEW_TITLE: 'Button',
  BUTTONS_LANGUAGE: 'Language',

  BUTTON_PLACE: 'Place in mobile application',
  BUTTON_URL: 'URL',
  BUTTON_COLOR: 'Color',
  BUTTON_IMAGE: 'Image',
  BUTTON_STATUS: 'Status',
  BUTTON_IMAGE_DELETE: 'Delete',

  SINGLE_BUTTON_NAME: 'Name',
  SINGLE_BUTTON_DESCRIPTION: 'Description',
  SINGLE_BUTTON_START_DATE: 'Start date',
  SINGLE_BUTTON_END_DATE: 'End date',
  SINGLE_BUTTON_PHOTO: 'Image',
  SINGLE_BUTTON_STATUS: 'Status',
  SINGLE_BUTTON_COLOR: 'Color',
  SINGLE_BUTTON_URL: 'URL',

  ADVERTISEMENTS_TITLE: 'Advertisements',
  ADVERTISEMENTS_STATUS: 'Status',
  ADVERTISEMENTS_LOCATION: 'Location',
  ADVERTISEMENTS_VIDEO: 'Video',
  ADVERTISEMENTS_LANGUAGE: 'Language',

  ADVERTISEMENTS_ADD_ADVERTISEMENT: 'Add advertisement',
  ADVERTISEMENTS_EDIT_ADVERTISEMENT: 'Edit advertisement',

  NOTIFICATIONS_TITLE: 'Notifications',
  NOTIFICATIONS_NAME_PL: 'Name (PL)',
  NOTIFICATIONS_NAME_EN: 'Name (EN)',
  NOTIFICATIONS_LOCATION: 'Location',
  NOTIFICATIONS_STATUS: 'Status',
  NOTIFICATIONS_TYPE: 'Type',
  NOTIFICATIONS_IMAGE: 'Image',
  NOTIFICATIONS_DESCRIPTION_PL: 'Description (PL)',
  NOTIFICATIONS_DESCRIPTION_EN: 'Description (EN)',
  NOTIFICATIONS_ROUTE_FILE: 'File',

  NOTIFICATIONS_PUSH_TITLE: 'PUSH Notifications',
  NOTIFICATIONS_PUSH_NAME_PL: 'Name (PL)',
  NOTIFICATIONS_PUSH_NAME_EN: 'Name (EN)',
  NOTIFICATIONS_PUSH_DESCRIPTION_PL: 'Description (PL)',
  NOTIFICATIONS_PUSH_DESCRIPTION_EN: 'Description (EN)',
  NOTIFICATIONS_PUSH_SENDER: 'Sender',
  NOTIFICATIONS_SEND_DATE: 'Send date',
  NOTIFICATIONS_PUSH_DESCRIPTION_PREVIEW_TITLE: 'Description preview',
  NOTIFICATIONS_PUSH_DESCRIPTION_PREVIEW: 'View',

  NOTIFICATIONS_ROUTE_TITLE: 'Routes notifications',
  NOTIFICATIONS_ROUTE_NAME_PL: 'Name (PL)',
  NOTIFICATIONS_ROUTE_NAME_EN: 'Name (EN)',
  NOTIFICATIONS_ROUTE_LOCATION: 'Location',
  NOTIFICATIONS_ROUTE_STATUS: 'Status',
  NOTIFICATIONS_ROUTE_DESCRIPTION_PL: 'Description (PL)',
  NOTIFICATIONS_ROUTE_DESCRIPTION_EN: 'Description (EN)',

  NOTIFICATIONS_ROUTE_ADD_NOTIFICATION: 'Add route notification',
  NOTIFICATIONS_ROUTE_EDIT_NOTIFICATION: 'Edit route notification',

  NOTIFICATIONS_ADD_NOTIFICATION: 'Add notification',
  NOTIFICATIONS_EDIT_NOTIFICATION: 'Edit notification',

  NOTIFICATIONS_PUSH_SEND: 'Send notification push',
  NOTIFICATIONS_PUSH_SEND_SUCCESS: 'Sending notification push successfully completed',

  NOTIFICATIONS_PUSH_SEND_NOTIFICATION_PUSH: 'Send notification push',

  CAMPUSES_TITLE: 'Campuses labels',
  CAMPUSES_ADD_CAMPUS: 'Add campus label',
  CAMPUSES_EDIT_CAMPUS: 'Edit campus label',

  CAMPUSES_NAME_PL: 'Name (PL)',
  CAMPUSES_NAME_EN: 'Name (EN)',

  SOS_CONTACTS_TITLE: 'SOS contacts list',
  SOS_CONTACTS_NAME: 'Name',
  SOS_CONTACTS_PHONE: 'Phone',
  SOS_CONTACTS_EMAIL: 'E-mail',
  SOS_CONTACTS_STATUS: 'Status',
  SOS_CONTACTS_PHONE_EMAIL_VALIDATOR: 'One of the contact fields must be filled in',

  SOS_CONTACTS_ADD_CONTACT: 'Add SOS contact',
  SOS_CONTACTS_EDIT_CONTACT: 'Edit SOS contact',

  PARKING_LOTS_TITLE: 'Parking lots',
  PARKING_LOTS_NAME_PL: 'Name (PL)',
  PARKING_LOTS_NAME_EN: 'Name (EN)',
  PARKING_LOTS_CAMPUS_PL: 'Campus name (PL)',
  PARKING_LOTS_CAMPUS_EN: 'Campus name (EN)',
  PARKING_LOTS_SYSTEM_ID: 'System ID',
  PARKING_LOTS_PARKING_LOT_ID: 'Parking lot ID',
  PARKING_LOTS_STATUS: 'Status',
  PARKING_LOTS_ALL: 'X',
  PARKING_LOTS_TAKEN: 'X',
  PARKING_LOTS_TOTAL: 'X',
  PARKING_LOTS_FREE: 'X',
  PARKING_LOTS_PERCENT: 'X',

  PARKING_LOTS_EDIT_PARKING_LOT: 'X',
  PARKING_LOTS_LEGEND: 'X',
  PARKING_LOTS_LEGEND_TITLE: 'X',
  PARKING_LOTS_LEGEND_SCOPE: 'X',
  PARKING_LOTS_LEGEND_DESCRIPTION: 'X',
  PARKING_LOTS_LEGEND_DESCRIPTION_TITLE_PL: 'X',
  PARKING_LOTS_LEGEND_DESCRIPTION_TITLE_EN: 'X',
  PARKING_LOTS_LEGEND_DESCRIPTION_DESC_PL: 'X',
  PARKING_LOTS_LEGEND_DESCRIPTION_DESC_EN: 'X',

  BEACONS_TITLE: 'X',
  BEACONS_EDIT_BEACON: 'X',
  BEACONS_EDIT_NAME: 'X',
  BEACONS_EDIT_LEVEL: 'X',
  BEACONS_EDIT_STATUS: 'X',
  BEACONS_EDIT_MAC: 'X',
  BEACONS_EDIT_BATTERY: 'X',
  BEACONS_EDIT_POWER: 'X',
  BEACONS_EDIT_DEVICE: 'X',
  BEACONS_EDIT_INTERVAL: 'X',
  BEACONS_EDIT_IS_OUTSIDE: 'X',
  BEACONS_EDIT_PROFILE: 'X',
  BEACONS_EDIT_RSSI: 'X',
  BEACONS_EDIT_PHOTO: 'X',

  BEACONS_NAME: 'X',
  BEACONS_MAC: 'X',
  BEACONS_BATTERY: 'X',

  OPINIONS_TITLE: 'X',
  OPTIONS_TEXT: 'X',
  OPTIONS_USER: 'X',
  OPTIONS_DATE: 'X',
  OPTIONS_ANSWERED: 'X',
  OPTIONS_USER_UNAUTHENTICATED: 'X',
  OPTIONS_DETAILS_REPLY: 'X',
  OPTIONS_DETAILS_REPLY_BODY: 'X',

  OPINION_REPLY_PUSH_MODAL_TITLE: 'X',
  OPINION_REPLY_SEND: 'X',
  OPINION_REPLY_PUSH_SUCCESS: 'X',
  OPINION_REPLY_TITLE: 'X',
  OPINION_REPLY_BODY: 'X',

  //Map forms
  MAP_FORM_CAMPUS_NAME: 'X',
  MAP_FORM_CAMPUS_NAME_EN: 'X',
  MAP_FORM_CAMPUS_KEY: 'X',
  MAP_FORM_CAMPUS_SOURCE_ID: 'X',
  MAP_FORM_CAMPUS_CATEGORY: 'X',

  MAP_FORM_BUILDING_NAME: 'X',
  MAP_FORM_BUILDING_NAME_EN: 'X',
  MAP_FORM_BUILDING_KEY: 'X',
  MAP_FORM_BUILDING_CAMPUS: 'X',
  MAP_FORM_BUILDING_ADDRESS: 'X',
  MAP_FORM_BUILDING_SOURCE_ID: 'X',
  MAP_FORM_BUILDING_CATEGORY: 'X',

  MAP_FORM_LEVEL_NAME: 'X',
  MAP_FORM_LEVEL_NAME_EN: 'X',
  MAP_FORM_LEVEL_KEY: 'X',
  MAP_FORM_LEVEL_BUILDING: 'X',
  MAP_FORM_LEVEL_SORT: 'X',
  MAP_FORM_LEVEL_LABEL_PL: 'X',
  MAP_FORM_LEVEL_LABEL_EN: 'X',
  MAP_FORM_LEVEL_PROFILE: 'X',
  MAP_FORM_LEVEL_SOURCE_ID: 'X',

  MAP_FORM_BEACONPLACE_NAME: 'X',
  MAP_FORM_BEACONPLACE_NAME_EN: 'X',
  MAP_FORM_BEACONPLACE_LEVEL: 'X',
  MAP_FORM_BEACONPLACE_STATUS: 'X',
  MAP_FORM_BEACONPLACE_MAC: 'X',
  MAP_FORM_BEACONPLACE_BATTERY: 'X',
  MAP_FORM_BEACONPLACE_POWER: 'X',
  MAP_FORM_BEACONPLACE_DEVICE: 'X',
  MAP_FORM_BEACONPLACE_INTERVAL: 'X',
  MAP_FORM_BEACONPLACE_IS_OUTSIDE: 'X',
  MAP_FORM_BEACONPLACE_PROFILE: 'X',
  MAP_FORM_BEACONPLACE_RSSI: 'X',

  MAP_FORM_ROOM_NAME: 'X',
  MAP_FORM_ROOM_NAME_EN: 'X',
  MAP_FORM_ROOM_KEY: 'X',
  MAP_FORM_ROOM_LEVEL: 'X',
  MAP_FORM_ROOM_CATEGORY: 'X',
  MAP_FORM_ROOM_PROFILE: 'X',

  MAP_FORM_WALL_LEVEL: 'X',
  MAP_FORM_WALL_ROUTINGAVAILABLE: 'X',
  MAP_FORM_WALL_PROFILE: 'X',

  MAP_FORM_POI_NAME: 'X',
  MAP_FORM_POI_NAME_EN: 'X',
  MAP_FORM_POI_KEY: 'X',
  MAP_FORM_POI_LEVEL: 'X',
  MAP_FORM_POI_PARENT_ROI: 'X',
  MAP_FORM_POI_MIN_ZOOM: 'X',
  MAP_FORM_POI_MAX_ZOOM: 'X',
  MAP_FORM_POI_PROFILE: 'X',
  MAP_FORM_POI_IS_VISIBLE: 'X',

  MAP_FORM_ROI_NAME: 'X',
  MAP_FORM_ROI_NAME_EN: 'X',
  MAP_FORM_ROI_KEY: 'X',
  MAP_FORM_ROI_DESCRIPTION: 'X',
  MAP_FORM_ROI_DESCRIPTION_EN: 'X',
  MAP_FORM_ROI_LEVEL: 'X',
  MAP_FORM_ROI_MIN_ZOOM: 'X',
  MAP_FORM_ROI_MAX_ZOOM: 'X',
  MAP_FORM_ROI_POI: 'X',
  MAP_FORM_ROI_SIZE: 'X',
  MAP_FORM_ROI_ALIAS: 'X',
  MAP_FORM_ROI_CATEGORY: 'X',
  MAP_FORM_ROI_PROFILE: 'X',
  MAP_FORM_ROI_DISABLE_FOR_DISABLED: 'X',
  MAP_FORM_ROI_UMED_ROOM: 'X',
  MAP_FORM_ROI_IS_VISIBLE: 'X',

  MAP_FORM_ROUTE_NAME: 'X',
  MAP_FORM_ROUTE_KEY: 'X',
  MAP_FORM_ROUTE_LEVEL: 'X',
  MAP_FORM_ROUTE_DISABLE_FOR_DISABLED: 'X',
  MAP_FORM_ROUTE_TRAVELTIME: 'X',
  MAP_FORM_ROUTE_STATUS: 'X',
  MAP_FORM_ROUTE_PROFILE: 'X',
  MAP_FORM_ROUTE_DIRECTION: 'X',

  MAP_FORM_CONNECTORS_NAME: 'X',
  MAP_FORM_CONNECTORS_PROFILE: 'X',
  MAP_FORM_CONNECTORS_KEY: 'X',
  MAP_FORM_CONNECTORS_CONNECTOR: 'X',
  MAP_FORM_CONNECTORS_LEVEL: 'X',
  MAP_FORM_CONNECTORS_DISABLE_FOR_DISABLED: 'X',
  MAP_FORM_CONNECTORS_DIRECTION: 'X',
  MAP_FORM_CONNECTORS_TRAVELTIME: 'X',
  MAP_FORM_CONNECTORS_ATTR: 'X',
  MAP_FORM_CONNECTORS_STATUS: 'X',

  MAP_FORM_CONNECTORS_DIRECTION_START_TO_END: 'X',
  MAP_FORM_CONNECTORS_DIRECTION_END_TO_START: 'X',
  MAP_FORM_CONNECTORS_DIRECTION_BOTH: 'X',

  MAP_FORM_CONNECTORS_ATTR_STAIRS: 'X',
  MAP_FORM_CONNECTORS_ATTR_MOVING_STAIRS: 'X',
  MAP_FORM_CONNECTORS_ATTR_LIFT: 'X',
  MAP_FORM_CONNECTORS_ATTR_ENTRY: 'X',
  MAP_FORM_CONNECTORS_ATTR_EXIT: 'X',

  MAP_FORM_DOOR_PROFILE: 'X',

  MAP_FORM_STAIRS_PROFILE: 'X',

  //error codes //todo: to change
  1000: 'AUTH_WRONG_LOGIN_OR_PASSWORD',
  1001: 'AUTH_NAME_REQUIRED',
  1002: 'AUTH_EMAIL_REQUIRED',
  1003: 'AUTH_EMAIL_WRONG_FORMAT',
  1004: 'AUTH_EMAIL_NOT_UNIQUE',
  1005: 'AUTH_PASSWORD_REQUIRED',
  1006: 'AUTH_PASSWORD_TOO_SHORT',
  1010: 'AUTH_WRONG_VERIFICATION_TOKEN',
  1018: 'AUTH_NAME_TOO_SHORT',
  1020: 'USER_NAME_REQUIRED',
  1021: 'USER_EMAIL_REQUIRED',
  1022: 'USER_EMAIL_WRONG_FORMAT',
  1023: 'USER_EMAIL_NOT_UNIQUE',
  1024: 'USER_PASSWORD_REQUIRED',
  1028: 'USER_LAST_NAME_REQUIRED',
  1029: 'USER_UNIT_NOT_EXISTS',
  1034: 'USER_DOES_NOT_EXIST',
  1035: 'AUTH_PASSWORD_RESET_EMAIL_NOT_SENT',
  1201: 'CATEGORY_NAME_REQUIRED',
  1202: 'CATEGORY_NAME_TOO_SHORT',
  1203: 'CATEGORY_NAME_TOO_LONG',
  1204: 'CATEGORY_COLOR_REQUIRED',
  1205: 'CATEGORY_COLOR_NOT_HEX',
  1207: 'CATEGORY_ICON_DOESNT_EXIST',
  1208: 'CATEGORY_ENABLED_WRONG_FORMAT',
  1209: 'CATEGORY_LANG_REQUIRED',
  1210: 'CATEGORY_LANG_IN',
  1211: 'CATEGORY_DELETING_FAILED',
  1212: 'CATEGORY_UPDATING_FAILED',
  1213: 'CATEGORY_CREATING_FAILED',
  1214: 'CATEGORY_DELETING_FAILED_NOT_EXISTS',
  1215: 'CATEGORY_DELETING_FAILED_EVENT_EXISTS',
  1216: 'CATEGORY_DELETING_FAILED_ARTICLE_EXISTS',
  1217: 'CATEGORY_CAN_NOT_BE_INACTIVE',
  1218: 'CATEGORY_NAME_EN_REQUIRED',
  1219: 'CATEGORY_NAME_EN_TOO_SHORT',
  1220: 'CATEGORY_NAME_EN_TOO_LONG',
  1230: 'CATEGORY_VISIBLE_REQUIRED',
  1231: 'CATEGORY_VISIBLE_BOOLEAN',
  1232: 'CATEGORY_VISIBLE_ICON_REQUIRED',
  1233: 'CATEGORY_VISIBLE_ICON_BOOLEAN',
  1234: 'CATEGORY_RANGE_REQUIRED',
  1235: 'CATEGORY_RANGE_INTEGER',
  1300: 'TYPE_CREATING_FAILED',
  1301: 'TYPE_DELETING_FAILED',
  1302: 'TYPE_UPDATING_FAILED',
  1303: 'TYPE_NAME_REQUIRED',
  1304: 'TYPE_NAME_TOO_SHORT',
  1305: 'TYPE_NAME_TOO_LONG',
  1306: 'TYPE_KEY_REQUIRED',
  1307: 'TYPE_KEY_TOO_SHORT',
  1308: 'TYPE_KEY_TOO_LONG',
  1309: 'TYPE_PARENT_DOESNT_EXISTS',
  1310: 'RELATED_CATEGORY_DOESNT_EXIST',
  1400: 'ATTRIBUTE_CREATING_FAILED',
  1401: 'ATTRIBUTE_UPDATING_FAILED',
  1402: 'ATTRIBUTE_DELETING_FAILED',
  1403: 'ATTRIBUTE_NAME_REQUIRED',
  1404: 'ATTRIBUTE_NAME_TOO_SHORT',
  1405: 'ATTRIBUTE_NAME_TOO_LONG',
  1406: 'ATTRIBUTE_LABEL_TOO_LONG',
  1407: 'ATTRIBUTE_TYPE_REQUIRED',
  1408: 'ATTRIBUTE_TYPE_NOT_IN_ALLOWED_VALUES',
  1409: 'ATTRIBUTE_GEOMETRY_TYPE_REQUIRED',
  1500: 'GEOMETRY_CREATING_FAILED',
  1501: 'GEOMETRY_UPDATING_FAILED',
  1502: 'GEOMETRY_DELETING_FAILED',
  1503: 'GEOMETRY_GEOMETRY_REQUIRED',
  1504: 'GEOMETRY_GEOMETRY_FORMAT',
  1505: 'GEOMETRY_TYPE_ID_REQUIRED',
  1506: 'GEOMETRY_TYPE_NOT_FOUND',
  1507: 'GEOMETRY_UNSUPPORTED_GEOMETRY_TYPE',
  1600: 'VALUE_CREATING_FAILED',
  1601: 'VALUE_UPDATING_FAILED',
  1602: 'VALUE_DELETING_FAILED',
  1603: 'VALUE_PAYLOAD_REQUIRED',
  1604: 'VALUE_PARENT_GEOMETRY_REQUIRED',
  1605: 'VALUE_PARENT_GEOMETRY_DOESNT_EXIST',
  1606: 'VALUE_PARENT_ATTRIBUTE_REQUIRED',
  1607: 'VALUE_PARENT_ATTRIBUTE_DOESNT_EXIST',
  1701: 'ARTICLE_NAME_REQUIRED',
  1702: 'ARTICLE_NAME_MINIMUM',
  1703: 'ARTICLE_NAME_MAXIMUM',
  1704: 'ARTICLE_CATEGORY_ID_REQUIRED',
  1705: 'ARTICLE_CATEGORY_ID_RELATION_FAILURE',
  1706: 'ARTICLE_PHOTO_ID_DOES_NOT_EXISTS',
  1707: 'ARTICLE_PHOTO_FILE_TYPE',
  1708: 'ARTICLE_DESCRIPTION_REQUIRED',
  1709: 'ARTICLE_DESCRIPTION_MINIMUM',
  1710: 'ARTICLE_DESCRIPTION_MAXIMUM',
  1711: 'ARTICLE_STATUS_REQUIRED',
  1712: 'ARTICLE_STATUS_IN',
  1713: 'ARTICLE_LANG_REQUIRED',
  1714: 'ARTICLE_LANG_IN',
  1715: 'ARTICLE_CAMPUS_NOT_EXISTS',
  1716: 'ARTICLE_CATEGORY_INACTIVE',
  1720: 'ARTICLE_CREATING_FAILED',
  1721: 'ARTICLE_DELETING_FAILED',
  1722: 'ARTICLE_UPDATING_FAILED',
  1801: 'EVENT_NAME_REQUIRED',
  1802: 'EVENT_NAME_MINIMUM',
  1803: 'EVENT_NAME_MAXIMUM',
  1804: 'EVENT_PHOTO_IMAGE_TYPE',
  1805: 'EVENT_PHOTO_FILE_TYPE',
  1806: 'EVENT_LOCATION_REQUIRED',
  1807: 'EVENT_LOCATION_CATEGORY_REQUIRED',
  1808: 'EVENT_LOCATION_VALUE_REQUIRED',
  1809: 'EVENT_LOCATION_JSON',
  1810: 'EVENT_DATE_REQUIRED',
  1811: 'EVENT_DATE_FORMAT',
  1812: 'EVENT_DESCRIPTION_REQUIRED',
  1813: 'EVENT_DESCRIPTION_MINIMUM',
  1814: 'EVENT_DESCRIPTION_MAXIMUM',
  1820: 'EVENT_CATEGORY_ID_REQUIRED',
  1821: 'EVENT_CATEGORY_ID_RELATION_FAILURE',
  1822: 'EVENT_STATUS_REQUIRED',
  1823: 'EVENT_STATUS_IN',
  1824: 'EVENT_LANG_REQUIRED',
  1825: 'EVENT_LANG_IN',
  1826: 'EVENT_CAMPUS_NOT_EXISTS',
  1827: 'EVENT_CATEGORY_INACTIVE',
  1830: 'EVENT_CREATING_FAILED',
  1831: 'EVENT_DELETING_FAILED',
  1832: 'EVENT_UPDATING_FAILED',
  1833: 'EVENT_DELETING_FAILED_EVENT',
  1834: 'EVENT_DELETING_FAILED_ARTICLE',
  1835: 'EVENT_DELETING_FAILED_DOES_NOT_EXIST',
  1901: 'USER_CREATING_FAILED',
  1902: 'USER_UPDATING_FAILED',
  1903: 'USER_DELETING_FAILED',
  1904: 'USER_DELETING_AD_FAILED',
  1905: 'USER_FIRST_NAME_TOO_LONG',
  1906: 'USER_FIRST_NAME_TOO_SHORT',
  1907: 'USER_LAST_NAME_TOO_LONG',
  1908: 'USER_PASS_TOO_SHORT',
  1909: 'USER_PASS_TOO_LONG',
  1910: 'USER_ROLES_REQUIRED',
  1911: 'ROLES_MUST_BE_AN_ARRAY',
  1912: 'ROLES_ITEM_NEEDS_TO_BE_NUMERIC',
  1913: 'USER_CHANGE_USER_AD_PASS',
  2000: 'ROLE_CREATING_FAILED',
  2001: 'ROLE_DELETING_FAILED',
  2002: 'ROLE_UPDATING_FAILED',
  2003: 'ROLE_NAME_REQUIRED',
  2004: 'ROLE_NAME_TOO_SHORT',
  2005: 'ROLE_NAME_TOO_LONG',
  2010: 'PERMISSION_CREATING_FAILED',
  2011: 'PERMISSION_DELETING_FAILED',
  2012: 'PERMISSION_UPDATING_FAILED',
  2013: 'PERMISSION_NAME_REQUIRED',
  2014: 'PERMISSION_NAME_TOO_SHORT',
  2015: 'PERMISSION_NAME_TOO_LONG',
  2101: 'SOS_NAME_REQUIRED',
  2102: 'SOS_NAME_MINIMUM',
  2103: 'SOS_NAME_MAXIMUM',
  2106: 'SOS_STATUS_REQUIRED',
  2110: 'SOS_DESCRIPTION_REQUIRED',
  2111: 'SOS_DESCRIPTION_MINIMUM',
  2112: 'SOS_DESCRIPTION_MAXIMUM',
  2113: 'SOS_PUSH_ALREADY_SENT',
  2120: 'SOS_CREATING_FAILED',
  2121: 'SOS_DELETING_FAILED',
  2122: 'SOS_UPDATING_FAILED',
  2200: 'FILE_UPLOAD_FAILED',
  2201: 'FILE_DELETING_FAILED',
  2202: 'FILE_UPDATING_FAILED',
  2203: 'FILE_REQUIRED',
  2204: 'FILE_MIME_NOT_ALLOWED',
  2205: 'FILE_TO_BIG',
  2300: 'BUTTON_NAME_REQUIRED',
  2301: 'BUTTON_NAME_MINIMUM',
  2302: 'BUTTON_NAME_MAXIMUM',
  2303: 'BUTTON_DESCRIPTION_REQUIRED',
  2304: 'BUTTON_DESCRIPTION_MINIMUM',
  2305: 'BUTTON_DESCRIPTION_MAXIMUM',
  2306: 'BUTTON_TYPE_REQUIRED',
  2307: 'BUTTON_URL_REQUIRED',
  2308: 'BUTTON_URL_URL',
  2314: 'BUTTON_ACTIVE_REQUIRED',
  2315: 'BUTTON_PHOTO_EXISTS',
  2316: 'BUTTON_PLACE_REQUIRED',
  2317: 'BUTTON_DATE_REQUIRED',
  2318: 'BUTTON_STATUS_REQUIRED',
  2319: 'BUTTON_STATUS_IN',
  2350: 'BUTTON_CREATING_FAILED',
  2351: 'BUTTON_UPDATING_FAILED',
  2352: 'BUTTON_DELETING_FAILED',
  2353: 'BUTTON_PLACE_TAKEN',
  2354: 'BUTTON_ACTIVE_IN',
  2355: 'BUTTON_PLACE_IN',
  2356: 'BUTTON_THEME_REQUIRED',
  2357: 'BUTTON_LANG_REQUIRED',
  2358: 'BUTTON_LANG_IN',
  2400: 'CAMPUS_NAME_REQUIRED',
  2401: 'CAMPUS_NAME_TOO_SHORT',
  2402: 'CAMPUS_NAME_TOO_LONG',
  2403: 'CAMPUS_LANG_REQUIRED',
  2404: 'CAMPUS_LANG_IN',
  2405: 'CAMPUS_IS_ENABLED_REQUIRED',
  2406: 'CAMPUS_IS_ENABLED_BOOLEAN',
  2407: 'CAMPUS_EVENT_EXISTS',
  2408: 'CAMPUS_ARTICLE_EXISTS',
  3000: 'ROUTER_COMMUNICATION_FAILED',
  6000: 'COMMERCIAL_CREATE_FAILED',
  6001: 'COMMERCIAL_NAME_REQUIRED',
  6002: 'COMMERCIAL_FILE_REQUIRED',
  6003: 'COMMERCIAL_FILE_EXISTS',
  6004: 'COMMERCIAL_LANG_REQUIRED',
  6005: 'COMMERCIAL_LANG_IN',
  6006: 'COMMERCIAL_LOCATION_REQUIRED',
  6007: 'COMMERCIAL_LOCATION_BEACON',
  6100: 'NOTIFICATION_CREATE_FAILED',
  6101: 'NOTIFICATION_NAME_PL_REQUIRED',
  6102: 'NOTIFICATION_NAME_PL_MIN',
  6103: 'NOTIFICATION_NAME_PL_MAX',
  6104: 'NOTIFICATION_NAME_EN_REQUIRED',
  6105: 'NOTIFICATION_NAME_EN_MIN',
  6106: 'NOTIFICATION_NAME_EN_MAX',
  6107: 'NOTIFICATION_DESCRIPTION_PL_REQUIRED',
  6108: 'NOTIFICATION_DESCRIPTION_PL_MIN',
  6109: 'NOTIFICATION_DESCRIPTION_PL_MAX',
  6110: 'NOTIFICATION_DESCRIPTION_EN_REQUIRED',
  6111: 'NOTIFICATION_DESCRIPTION_EN_MIN',
  6112: 'NOTIFICATION_DESCRIPTION_EN_MAX',
  6113: 'NOTIFICATION_LOCATION_REQUIRED',
  6114: 'NOTIFICATION_LOCATION_ROI',
  6115: 'NOTIFICATION_LOCATION_EXISTS',
  6116: 'NOTIFICATION_FILE_REQUIRED',
  6117: 'NOTIFICATION_FILE_EXISTS',
  6118: 'NOTIFICATION_STATUS_REQUIRED',
  6119: 'NOTIFICATION_STATUS_IN',
  6120: 'NOTIFICATION_TYPE_REQUIRED',
  6121: 'NOTIFICATION_TYPE_IN',
  6122: 'NOTIFICATION_PUSH_CAN_NOT_BE_DELETED',
  6123: 'NOTIFICATION_PUSH_CAN_NOT_BE_UPDATED',
  6200: 'SOS_CREATE_FAILED',
  6201: 'SOS_SOS_REQUIRED',
  6202: 'SOS_SOS_EXISTS',
  6203: 'SOS_TITLE_REQUIRED',
  6204: 'SOS_TITLE_MIN',
  6205: 'SOS_TITLE_MAX',
  6206: 'SOS_BODY_REQUIRED',
  6207: 'SOS_BODY_MIN',
  6208: 'SOS_BODY_MAX',
  6300: 'EMAIL_SMS_CREATE_FAILED',
  6301: 'EMAIL_SMS_NAME_REQUIRED',
  6302: 'EMAIL_SMS_PHONE_REQUIRED',
  6303: 'EMAIL_SMS_EMAIL_REQUIRED',
  6304: 'EMAIL_SMS_EMAIL_EMAIL',
  6305: 'EMAIL_SMS_STATUS_REQUIRED',
  6306: 'EMAIL_SMS_STATUS_IN',
  6400: 'PARKING_CREATE_FAILED',
  6401: 'PARKING_NAME_PL_REQUIRED',
  6402: 'PARKING_NAME_PL_MIN',
  6403: 'PARKING_NAME_PL_MAX',
  6404: 'PARKING_NAME_EN_REQUIRED',
  6405: 'PARKING_NAME_EN_MIN',
  6406: 'PARKING_NAME_EN_MAX',
  6407: 'PARKING_STATUS_REQUIRED',
  6408: 'PARKING_STATUS_IN',
  6701: 'RATING_RESPONSE_TITLE_REQUIRED',
  6702: 'RATING_RESPONSE_TITLE_MIN',
  6703: 'RATING_RESPONSE_TITLE_MAX',
  6711: 'RATING_RESPONSE_BODY_REQUIRED',
  6712: 'RATING_RESPONSE_BODY_MIN',
  6713: 'RATING_RESPONSE_BODY_MAX',
  9999: 'UNKNOWN_ERROR',
};
